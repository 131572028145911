<template>
  <v-dialog
    v-model="isVisibleDialog"
    scrollable
    persistent
    max-width="600px"
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">{{ $t("edit") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                v-if="authUser.isAdmin"
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="oem.tenant_type"
                  dense
                  disabled
                  :items="productOptions"
                  item-value="slug"
                  item-text="name"
                  :label="$t('tenant_type_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                v-if="oem.tenant_type == 'fleet'"
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="oem.is_independent"
                  dense
                  disabled
                  :items="fleetTypeOptions"
                  item-value="id"
                  item-text="name"
                  :label="$t('fleet_type_*')"
                  :rules="[rules.required]"
                  @change="() => delete oem.oem_b2b_ids"
                />
              </v-col>
              <v-col
                v-if="oem.tenant_type == 'fleet' && oem.is_independent == '0'"
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="oem.oem_b2b_ids"
                  dense
                  disabled
                  :items="oemBusinessList"
                  item-value="id"
                  item-text="tenant_name"
                  :label="$t('business_name_*')"
                  :rules="[rules.required]"
                  multiple
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="oem.vehicle_tech"
                  dense
                  disabled
                  :items="['ev', 'ice']"
                  :label="$t('select_tenant_tech_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="oem.name"
                  dense
                  :label="$t('name_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="oem.email"
                  dense
                  disabled
                  :label="$t('email_*')"
                  :rules="[rules.required, rules.email]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="oem.country_code"
                  dense
                  :items="countryCodeLists"
                  item-value="id"
                  item-text="name"
                  :label="$t('country_code_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="oem.contact_no"
                  dense
                  type="number"
                  :label="$t('contact_*')"
                  :rules="[
                    rules.required,
                    rules.startFrom(3),
                    rules.min_length(10),
                    rules.max_length(10),
                  ]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="oem.address"
                  dense
                  :label="$t('address_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="oem.no_of_allowed_users"
                  dense
                  type="number"
                  :min="3"
                  :label="$t('no_of_users_*')"
                  :rules="[rules.min_quantity(3)]"
                />
              </v-col>
              <!-- v-if="oem.tenant_type == 'fleet'" -->
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="oem.over_speed_limit"
                  dense
                  type="number"
                  :label="$t('over_speed_limit_*')"
                  :rules="[
                    rules.required,
                    rules.min_quantity(40),
                    rules.max_quantity(120),
                  ]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="oem.is_active"
                  dense
                  :items="statusLists"
                  item-value="id"
                  item-text="name"
                  :label="$t('status_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <!-- :placeholder="oem.business_logo ? '' : oem.logo_url" -->
                <v-file-input
                  v-model="oem.logo"
                  dense
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-camera"
                  :label="$t('business_logo')"
                  :rules="[rules.file_size]"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-img
                  lazy-src="https://picsum.photos/id/11/10/6"
                  max-height="100"
                  max-width="100"
                  :src="oem.logo_url"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <span class="text-h3">Admin</span>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="oem.user_name"
                  dense
                  :label="$t('poc_name_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="oem.user_email"
                  dense
                  disabled
                  :label="$t('poc_email_*')"
                  :rules="[rules.required, rules.email]"
                />
              </v-col>
            </v-row>
            <product-with-tiers
              :item-list="oem.productTiers"
              @change="onChangeProduct"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <small>{{ $t("indicates_required_field") }}</small>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="toggleEdit()"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="isLoadingSave"
            @click="updateItem(oem)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  components: {
    ProductWithTiers: () =>
      import("../../../../components/sections/ProductWithTiers.vue"),
  },
  mixins: [validationRules],
  props: {
    isVisibleDialog: Boolean,
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    items: {
      type: Object,
      default() {
        return {};
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      isLoadingSave: false,
      oem: JSON.parse(JSON.stringify(this.items)), //for Deep Clone
      productOptions: [
        { slug: "oem", name: "OEM" },
        { slug: "fleet", name: "Fleet" },
      ],
      fleetTypeOptions: [
        { id: "0", name: "Dependent" },
        { id: "1", name: "Independent" },
      ],
      statusLists: [
        {
          id: "1",
          name: "Active",
        },
        {
          id: "0",
          name: "Inactive",
        },
      ],
      countryCodeLists: [
        {
          id: "+92",
          name: "+92",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      oemBusinessList: "oemBusiness/getOEMB2BList",
      meta: "tenantsManagement/getMeta",
    }),
  },
  // async beforeMount() {
  //   // Set business Logo Name
  //   let nameArr = this.oem.logo_url.split("/");
  //   let index = nameArr.length - 1;
  //   const name = nameArr[index];
  //   const ext = name.split(".")[1];
  //   const f = new File([""], name, { type: `image/${ext}` });
  //   this.oem.business_logo = f;
  // },
  methods: {
    async updateItem(oem) {
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;

        // this.oem.name = this.oem.oem_name;
        // this.oem.email = this.oem.user_email;
        // if (oem.tenant_type == "oem") {
        //   delete oem.over_speed_limit;
        // }
        await this.$store
          .dispatch("tenantsManagement/update", oem)
          .then(async () => {
            await this.$store.dispatch("tenantsManagement/list", this.options);
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
            this.isLoadingSave = false;
            this.toggleEdit();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
    onChangeProduct(product_tier_ids) {
      this.oem = { ...this.oem, product_tier_ids };
    },
  },
};
</script>